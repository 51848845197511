/**
 * @module 404 Error
 * @description 404 Error Page
 */
import Head from 'next/head';
import Template from '@templates/Landing';
import SiteError from '@organisms/SiteError';

/**
 * @function Page
 * @description Page
 */
const Page = () => (
  <>
    <Head>
      <title>Not Found | Autojack21</title>
    </Head>

    <Template
      title="404: Not Found "
      main={
        <SiteError title="404 Error" />
      }
    />
  </>
);

export default Page;
